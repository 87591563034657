<template>
    <div class="dashboard-wrap">
        <h1 class="dashboard-title justify-content-between">
            <span>Вывод средств</span>
            <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
        </h1>
    </div>
    <div class="tab-arrow">
        <div class="tab horizontal account default-color">
            <router-link to="/dashboard/withdraw" class="tab-button">Криптовалюты</router-link>
            <router-link to="/dashboard/payment-services/259" class="tab-button">Карты Банков</router-link>
            <router-link to="/dashboard/withdraw/bank" class="tab-button">Банк</router-link>
        </div>
    </div>
    <div class="dashboard-wrap">
        <div class="dashboard-list">
            <div class="row" v-if="category == '' || category == undefined">
                <template v-for="item in list" :key="item">
                    <div class="col-6 col-md-4 col-lg-3 col-xl-3" v-if="item.can_withdraw === true && search(item)">
                        <router-link :to="'/dashboard/withdraw/detail/' + item.currency" class="list-item darks">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span>{{ item.balance }}</span>
                                </div>
                                <small class="list-item-button d-none"></small>
                            </div>
                        </router-link>
                    </div>
                </template>
            </div>
            <div class="row" v-if="category == 'bank'">
                <template v-for="item in cashList" :key="item">
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4" v-on:click="getMethodDetail(item)">
                        <div class="list-item">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span class="active">{{ item.name }}</span>
                                </div>
                                <small class="list-item-button d-none"></small>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content" v-if="!confirm">
            <h1 class="modal-title text-center mb-4">{{ detail.name }}</h1>
            <div class="modal-wrap">
                <div class="row">
                    <div class="col-12 col-md-6 m-mb">
                        <div class="profile-info-column mt-0 icon-none">
                            <div>
                                <span>Минимальная сумма</span>
                                <b>{{ detail.min_qty }} {{ detail.currency }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 m-mb">
                        <div class="profile-info-column mt-0 icon-none">
                            <div>
                                <span>Максимальная сумма</span>
                                <b>{{ detail.max_qty }} {{ detail.currency }}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-12 col-md-6">
                        <div class="profile-info-column mt-0 icon-none">
                            <div>
                                <span>Комиссия</span>
                                <b>{{ detail.commission }}%</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="profile-info-column mt-0 icon-none">
                            <div>
                                <span>Курс</span>
                                <b class="text-success">1.00 USDT = {{ detail.rate }} {{ detail.currency }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form my-4">
                <label class="form-label">Сумма к списанию <b>USDT</b></label>
                <div class="form-group mb-2">
                    <input type="number" class="form-input" v-model="amount" placeholder="Введите сумму" required>
                </div>
                <label class="form-label">Сумма к зачислению <b>{{ detail.currency }}</b></label>
                <div class="form-group mb-2">
                    <input type="number" class="form-input" v-model="quantity" readonly>
                </div>
                <label class="form-label">Введите банковский счет</label>
                <div class="form-group mb-4">
                    <input type="text" class="form-input" v-model="requisite" placeholder="Введите банковский счет" required>
                </div>
                <button class="button block lg" v-on:click="createBankWithdraw" :disabled="loading">Продолжить</button>
            </div>
            <p class="modal-text text-center" v-if="detail.info.description" v-html="detail.info.description"></p>
            <button class="button block lg" v-on:click="closeModal">Закрыть</button>
        </div>
        <div class="modal-content" v-if="confirm">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm.amount }} USDT</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ confirm.debit }} {{ confirm.currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ confirm.commission }} {{ confirm.currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Курс</div>
                    <div class="dashboard-table-column text-success">1.00 USDT = {{ detail.rate }} {{ detail.currency }}</div>
                </div>
            </div>
            <button class="button block lg" :disabled="loading" v-on:click="confirmBankWithdraw">Подтвердить</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalStatus: false,
            searchValue: '',
            category: '',
            detail: '',
            amount: 0,
            quantity: 0,
            requisite: '',
            twofactor: ''
        }
    },
    mounted() {
        this.category = this.$router.currentRoute.value.params.category
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        list() {
            return this.$store.getters.getCurrencies
        },
        cashList() {
            return (this.$store.getters.getWithdrawCashList)
            ? this.$store.getters.getWithdrawCashList
            : this.$store.dispatch('getWithdrawCashList')
        },
        confirm() {
            return this.$store.getters.getWithdrawConfirm
        }
    },
    watch: {
        $route() {
            this.category = this.$router.currentRoute.value.params.category
        },
        amount() {
            this.calculate()
        }
    },
    methods: {
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            if(this.category === 'bank') {
                this.$store.dispatch('clearBankMethod')
            }
            this.amount = 0
            this.quantity = 0
            this.requisite = ''
            this.modalStatus = false
        },
        search(item) {
            return (item.currency.search(this.searchValue) !== -1 || item.currency.toLowerCase().search(this.searchValue) !== -1) ? true : false
        },
        getMethodDetail(item) {
            this.detail = item
            this.showModal()
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        numberFormat(number, float) {
            var string, result
            if(String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] +'.'+ string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        calculate() {
            if(this.amount > 0) {
                const quantity = this.amount * this.detail?.rate
                const commission = (quantity * parseFloat(this.detail?.commission)) / 100
                const result = quantity - commission
                this.quantity = this.numberFormat((result >= 0 ? result : 0), 0)
            } else {
                this.quantity = 0
            }
        },
        createBankWithdraw() {
            if(this.quantity < parseFloat(this.detail.min_qty)) {
                this.notification('Минимальная сумма ' + this.detail.min_qty +' '+ this.detail.currency)
            } else {
                if(!this.requisite || this.requisite === '') {
                    this.notification('Введите банковский счет')
                } else {
                    const data = {
                        service: this.detail.id,
                        amount: this.amount,
                        requisite: this.requisite
                    }
                    // console.log(data)
                    this.$store.dispatch('createBankWithdraw', data)
                }
            }
        },
        confirmBankWithdraw() {
            const data = {
                'guid': this.confirm.id
            }
            if(this.twofactor) {
                data['2fa_otp'] = String(this.$refs.code.value)
            }
            this.$store.dispatch('confirmBankWithdraw', data)
        }
    }
}
</script>